<template>
    <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :promises.sync="settingsItemEditCardPromises"
        :formPromises.sync="computedPromises" >
        <template v-slot:form-fields>
            <slot name="form-fields"/>
        </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"

export default {
    name: "SettingsItemEditCard",
    props: {
        promises: {
            default: () => []
        }
    },
    emits: ["update:promises"],
    data() {
        return {
            settingsItemEditCardPromises: []
        }
    },
    inheritAttrs: false,
    components: {
        DetailItemEditCard,
    },
    computed: {
        computedPromises: {
            get() {
                return this.promises
            },
            set(newValue) {
                this.$emit("update:promises", newValue)
            }
        } 
    },
}

</script>