<template>
    <SettingsItemEditCard confirmation-message="Werkrooster werd succesvol gewijzigd"
                          title="Bewerk werkrooster"
                          :detailViewRouteLocation="{name: routeNames.SETTINGS_WORK_SCHEDULES}"
                          :update-store-action="isCreateRoute ? 'workSchedulesModule/createWorkSchedule' : 'workSchedulesModule/updateWorkSchedule'"
                          :update-store-action-payload="getPayload"
                          item-name="werkroosters"
                          :promises.sync="promises">
      <template v-slot:form-fields>
        <v-row>
            <v-col cols="12">
                <h2>Contractueel verplichte uren</h2>
            </v-col>
            <v-col cols="12" lg="7">
                <UserWorkScheduleTable v-model="workSchedule" :editable="true"></UserWorkScheduleTable>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h2>Aanvangsuur werkdag</h2>
            </v-col>
          <v-col cols="12" lg="7">
            <WeekdaysTable v-model="workSchedule.startTimePerDay" editable :vertical="!$vuetify.breakpoint.xl">
                <template v-slot:editableCell="slotProps">
                  <TimePicker :value="slotProps.cellValue" @input="slotProps.updateMethod($event)" clearable class="font-size-inherit"/>
                </template>
            </WeekdaysTable>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="7">
                <DayPlanningComponent v-model="workPlanning"/>
            </v-col>
        </v-row>
        </template>
    </SettingsItemEditCard>
</template>

<script>
import routeNames from "@/router/RouteNames";
import UserWorkScheduleTable from "../../components/userDetail/UserWorkScheduleTable.vue";
import SettingsItemEditCard from "./SettingsItemEditCard.vue";
import WeekdaysTable from "@/components/shared/WeekdaysTable";
import TimePicker from "@/components/shared/fields/TimePicker.vue";
import subMinutes from "date-fns/subMinutes";
import {parseStringToDate} from "@/shared/utils/dateUtils";
import DayPlanningComponent from "@/components/planning/DayPlanningComponent";


export default {
    name: "WorkScheduleDetailsView",
    components: {WeekdaysTable, UserWorkScheduleTable, SettingsItemEditCard, TimePicker, DayPlanningComponent},
    data() {
        return {
            workSchedule: {
                id: !this.$route.params.id || this.$route.params.id === "create" ? null : this.$route.params.id,
                name: null,
                hoursPerDay: {
                    MONDAY: 8,
                    TUESDAY: 8,
                    WEDNESDAY: 8,
                    THURSDAY: 8,
                    FRIDAY: 8,
                    SATURDAY: 0,
                    SUNDAY: 0,
                },
                startTimePerDay: {
                    MONDAY: null,
                    TUESDAY: null,
                    WEDNESDAY: null,
                    THURSDAY: null,
                    FRIDAY: null,
                    SATURDAY: null,
                    SUNDAY: null
                },
                breakLength: 0.5,
                organizationId: this.$store.state.authModule.user.organizationId,
            },
            workPlanning: {
                MONDAY: null,
                TUESDAY: null,
                WEDNESDAY: null,
                THURSDAY: null,
                FRIDAY: null,
                SATURDAY: null,
                SUNDAY: null
            },
            routeNames,
            promises: []
        }
    },
    created() {
        if (!this.isCreateRoute) {
            this.promises.push(this.$store.dispatch('workScheduleModule/fetchWorkScheduleById', this.$route.params.id)
                .then((result) => {                
                    if (result) {
                        // Fills in the data for hours per day and start hours.
                        this.workSchedule = {
                            ...result,
                            // setDefaultStartTimePerDay checks if startTimePerDay contains valid data. If not set the time to null for all fields.
                            startTimePerDay: this.setDefaultStartTimePerDay(result.startTimePerDay),
                        }
                        this.workPlanning = this.setDefaultWorkPlanning(result.workPlanning)
                    }
                }
            ))
        }
    },
    computed: {
        isCreateRoute() {
            return !this.$route.params.id || this.$route.params.id === "create"
        },
        getPayload() {        
            return {
                ...this.workSchedule,
              startTimePerDay: Object.fromEntries(Object.entries(this.workSchedule.startTimePerDay).map(([day, time]) => [day, time ? subMinutes(time, time.getTimezoneOffset()) : null])),
              workPlanning: this.workPlanning
            }
        },
      },
    methods: {
        setDefaultStartTimePerDay(startTimePerDay) {
            if (startTimePerDay) {
                return Object.fromEntries(Object.entries(startTimePerDay).map(([day, time]) => [day, time ? parseStringToDate(time) : null]))
            }else {
                return {
                        MONDAY: null,
                        TUESDAY: null,
                        WEDNESDAY: null,
                        THURSDAY: null,
                        FRIDAY: null,
                        SATURDAY: null,
                        SUNDAY: null
                  }
            }
        },
        setDefaultWorkPlanning(workPlanning)  {
            if (workPlanning) {
                return workPlanning
            }else {
                return {
                        MONDAY: null,
                        TUESDAY: null,
                        WEDNESDAY: null,
                        THURSDAY: null,
                        FRIDAY: null,
                        SATURDAY: null,
                        SUNDAY: null
                  }
            }
        }
    },
}
</script>
