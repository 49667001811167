<template>
    <v-simple-table :class="{'required_star_absolute': editable && required}">
        <template v-slot:default>
            <tbody v-if="breakPoint">
                <tr :class="{'background--cultured-gray font-weight-bold': index === currentDayIndex}" v-for="(value, index) in getMappedValuePerday" :key="value.day">
                    <td>{{ translateDay(value.day) }}</td>
                    <td class="text-right w-50">
                        <slot v-if="editable" name="editableCell" v-bind:cellValue="value.mappedValue" v-bind:updateMethod="(mappedValue) => setMappedValueForDay(value.day, mappedValue)"></slot>
                        <slot v-else name="cell" v-bind:cellValue="value.mappedValue">{{ value.mappedValue }}</slot>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td :class="{'background--cultured-gray font-weight-bold': index === currentDayIndex}" v-for="(value, index) in getMappedValuePerday" :key="value.day">{{ translateDayAbbreviation(value.day) }}</td>
                </tr>
                <tr>
                    <td class="text-left weekdays-table__value-column--equal-size" :class="{'background--cultured-gray font-weight-bold': index === currentDayIndex}" v-for="(value, index) in getMappedValuePerday" :key="value.day">
                        <slot v-if="editable" name="editableCell" v-bind:cellValue="value.mappedValue" v-bind:updateMethod="(mappedValue) => setMappedValueForDay(value.day, mappedValue)"></slot>
                        <slot v-else name="cell" v-bind:cellValue="value.mappedValue">{{ value.mappedValue }}</slot>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import {orderByWeekdays} from "@/shared/utils/dateUtils";
import {translateDayAbbreviation, translateDay} from "@/shared/utils/translateUtils";

export default {
    name: "WeekdaysTable",
    props: {
        vertical: {
            type: Boolean,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        value: Object,
    },
    emits: ['update:value'],
    methods: {
        translateDayAbbreviation,
        translateDay,
        setMappedValueForDay(day, mappedValue) {
            this.modelValue[day] = mappedValue
        }
    },
    computed: {
        getMappedValuePerday() {
            if (!this.modelValue) {
                return null
            }
            return orderByWeekdays(Object.entries(this.modelValue).map(([day, mappedValue]) => ({day, mappedValue})), (i) => i.day)
        },
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update:value', newValue)
            }
        },        
        currentDayIndex() {
            const dayIndex = new Date().getDay() - 1
            return dayIndex < 0 ? 6 : dayIndex
        },
        breakPoint() {
            if (this.vertical == null) {
                return this.$vuetify.breakpoint.xsOnly
            }

            return this.vertical
        }
    }
}

</script>
<style lang="scss">

.weekdays-table__value-column--equal-size {
  // as we have 7 columns (7 weekdays), give each column an equal 'fixed' size
  width: calc(100% / 7);
}

</style>