<template lang="">
    <v-dialog
      :value="show"
      max-width="500px" 
      @click:outside="abort"
      @keydown="onKeyDown"
    >
    <v-card v-if="show">
        <BasicToolbar :title="title" @close="abort">
            <template slot="actions">
                <v-btn v-if="isExistingEvent" icon dark @click="remove">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </BasicToolbar>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <TimePicker v-model="event.start" label="Van" required/>
                </v-col>
                <v-col cols="6">
                    <TimePicker v-model="event.end" label="Tot" required :min="event.start"/>
                </v-col>
            </v-row>
            <v-card-actions class="pl-4">
                <v-btn
                type="button" color="secondary"
                :outlined="true"
                @click="abort"
                >
                {{ abortButtonText }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                type="button" color="secondary"
                @click="confirm"
                >
                {{ confirmButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card-text>
    </v-card>
    </v-dialog>
</template>
<script>
import BasicToolbar from "@/components/basic/BasicToolbar";
import TimePicker from "@/components/shared/fields/TimePicker.vue";

export default {
    name: "EditDayPlanningEventDialog",
    components: {
        BasicToolbar,
        TimePicker
    },
    props: {
        confirmCallback: {
            type: Function,
            required: true
        },
        removeCallback: {
            type: Function,
            required: true
        },
    },
    data() {
        return {
            abortButtonText: "Abort",
            confirmButtonText:"Confirm",
            title:"Wijzig planning event",
            event: null,
        }
    },
    methods: {
        open(eventToOpen) {
            if (eventToOpen && !this.isExistingEvent) {
                this.event = {...eventToOpen}
            }
        },
        abort(){
            this.event = null
        },
        confirm(){
            this.confirmCallback(this.event)
            this.event = null
        },
        remove() {
            this.removeCallback(this.event)
            this.event = null
        },
        onKeyDown(event) {
            if (event.key === "Escape") {
                this.abort()
            }
        }
    },
    computed: {
        show() {
            return !!this.event
        },
        isExistingEvent() {
            return this.event?.arrayIndex === 0 || !!this.event?.arrayIndex
        },
    }
}
</script>