<template>
    <div>
        <v-row>
            <v-col>
                <BasicInformationItem description="Naam" v-model="workSchedule.name" :description-class="{'required_star': editable}">
                    <template v-if="editable" v-slot:value-display="slotProps">
                        <v-text-field dense :value="slotProps.value" @input="slotProps.setValue" :rules="nameRules"></v-text-field>
                    </template>
                </BasicInformationItem>
            </v-col>
            <v-col>
                <BasicInformationItem description="Pauze (min)" v-model="breakTimeAsMinutes" :description-class="{'required_star': editable}">
                    <template v-if="editable" v-slot:value-display="slotProps">
                        <v-text-field dense :value="slotProps.value" @input="slotProps.setValue" :rules="breakLengthRules">
                            <template v-slot:append-outer>min</template>
                        </v-text-field>
                    </template>
                </BasicInformationItem>
            </v-col>
            <v-col>
                <BasicInformationItem description="Totaal werkuren" :value="getTotalHours + 'u'">
                </BasicInformationItem>
            </v-col>
        </v-row>
        <v-simple-table :class="{'required_star_absolute': editable}">
            <template v-slot:default>
                <tbody v-if="$vuetify.breakpoint.xsOnly">
                    <tr :class="{'background--cultured-gray font-weight-bold': index == currentDayIndex}" v-for="(value, index) in getHoursPerday" :key="value.day">
                        <td>{{ translateDay(value.day) }}</td>
                        <td>
                            <v-text-field v-if="editable" type="number" :value="value.hours" @input="setHoursForDay(value.day, $event)" :rules="hourRules"></v-text-field>
                            <template v-else>{{ value.hours + 'u' }}</template>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td :class="{'background--cultured-gray font-weight-bold': index == currentDayIndex}" v-for="(value, index) in getHoursPerday" :key="value.day">{{ translateDayAbbreviation(value.day) }}</td>
                    </tr>
                    <tr>
                        <td :class="{'background--cultured-gray font-weight-bold': index == currentDayIndex}" v-for="(value, index) in getHoursPerday" :key="value.day">
                            <v-text-field v-if="editable" type="number" :value="value.hours" @input="setHoursForDay(value.day, $event)" :rules="hourRules"></v-text-field>
                            <template v-else>{{ value.hours + 'u' }}</template>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import BasicInformationItem from '../shared/BasicInformationItem.vue';
import {orderByWeekdays} from "@/shared/utils/dateUtils";
import { hoursToMinutes } from "date-fns"
import {translateDayAbbreviation, translateDay} from "@/shared/utils/translateUtils";

export default {
    name: "UserWorkScheduleTable",    
    model: {
        prop: 'workSchedule',
        event: 'update:workSchedule'
    },
    props: {
        workSchedule: {
            required: true
        },
        editable: {
            default: false
        }
    },
    components: {
        BasicInformationItem,
    },
    methods: {
        translateDayAbbreviation,
        translateDay,
        setHoursForDay(day, hours) {
            this.$set(this.workSchedule.hoursPerDay, day, Number(hours))
        }
    },
    computed: {
        getTotalHours() {
            if (!this.workSchedule?.hoursPerDay) {
                return null
            }
            return Object.values(this.workSchedule.hoursPerDay).reduce((a, b) => a + b, 0)
        },
        breakTimeAsMinutes: {
            get() {
                return hoursToMinutes(this.workSchedule.breakLength)
            },
            set(newValue) {
                this.workSchedule.breakLength = newValue / 60
            }
        },
        currentDayIndex() {
            const dayIndex = new Date().getDay() - 1
            return dayIndex < 0 ? 6 : dayIndex
        },
        getHoursPerday() {
            if (!this.workSchedule?.hoursPerDay) {
                return null
            }
            return orderByWeekdays(Object.entries(this.workSchedule.hoursPerDay).map(([day, hours]) => ({day, hours})), (i) => i.day)
        },
        nameRules() {
            return [v => !!v || "De naam van een werkrooster moet ingevuld worden"]
        },
        hourRules() {
            return [
                v => v !== "" || "Aantal uren moet ingevuld worden",
                v => v >= 0 || "Aantal uren moet 0 of groter zijn",
                v => v <= 24 || "Aantal uren moet 24 of kleiner zijn"
            ]
        },
        breakLengthRules() {
            return [
                v => v !== "" || "Pauze moet ingevuld worden",
                v => v >= 0 || "Pauze moet 0 minuten of meer zijn",
                v => v <= 60 || "Pauze moet 60 minuten of minder zijn"
            ]
        },
    }
}
</script>