<template>
    <v-text-field v-bind="$attrs" type="time" v-model="modelValue" :error-messages="errorMessages" :error="error" :required="required" class="persist-button"/>
</template>

<script>
import { format } from 'date-fns'
import { checkRules as utilsCheckRules } from "../../../shared/utils/inputRulesUtils";

export default {
    name: "TimePicker",
    inheritAttrs: false,
    props: {
        value: Date,
        min: {
            type: Date
        },
        max: {
            type: Date
        },
        rules: {
            type: Array
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            error: false,
            errorMessages: []
        }
    },
    computed: {
        modelValue: {
            get() {
                if (!this.value) {
                    return null
                }
              return format(this.value, "HH:mm")
            },
            set(newValue) {
                const splitValue = newValue ? newValue.split(":") : [null, null]
                const hours = parseInt(splitValue[0])
                const minutes = parseInt(splitValue[1])

                if (isNaN(hours) || isNaN(minutes)) {
                    if (!this.required) {
                        this.$emit('input', null)
                    }
                    return
                }

                const date = this.value ? new Date(this.value) : new Date()

                date.setHours(hours)
                date.setMinutes(minutes)
                date.setSeconds(0)
                date.setMilliseconds(0)
                this.$emit('input', date)
            },
        },
        getRules() {
            if (this.rules) {
                return this.rules
            }

            const rules = []

            if (this.required) {
                rules.push(v => !!v || "Tijd moet ingevuld worden")
            }

            if (this.min) {
            rules.push(v => (!v || v >= this.min) || `Tijd kan niet voor ${format(this.min, "HH:mm")} zijn`)
            }

            return rules
        }
    },
    methods: {
        checkRules() {
            const result = utilsCheckRules(this.value, this.getRules)
            this.errorMessages = result.messages
            this.error = result.error
        }
    },
    watch:  {
        value() {
            this.checkRules()
        },
        min: {
            handler: function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return
                }

                if (this.min > this.value) {
                    this.$emit('input', this.min)
                } else {
                    this.checkRules()
                }
            },
        }
    }
}
</script>